const mockDataSchools = require('../mockDataSchools.json')
const mockDataHighSchools = require('../mockDataHighSchools.json')
const mockDataFaculties = require('../mockDataFaculties.json')
const mockDataEmployers = require('../mockDataEmployers.json')

let isActiveFilter = false

const capitalizeFirstLetter = string => {
  if (!string) return ''
  return string[0].toUpperCase() + string.slice(1)
}

const employerCardComponent = employer => {
  return `
      <div class='card paper blue'>
        <div class="image-holder"></div>
        <div class='paper small-padding' style="height: 100%">
          <div class='title-wrapper'>
            <p class='category'>${capitalizeFirstLetter(employer.category) || 'Spoločnosť'}</p>
            <h4 class='title'>${employer.name}</h4>
          </div>
        </div>
      </div>
    `
}

const schoolCardComponent =
  (type, category = '') =>
  school => {
    const splitLanguages = school.language?.split('/')
    const limitedLanguages = splitLanguages?.slice(0, 4)
    const isMoreThanLimit = splitLanguages?.length > 4

    return `
      <a href="${type}-${school.id}" class="result-card">
        <div class='card paper blue'>
          <div class="image-holder"></div>
          <div class='paper small-padding' style="height: 100%">
            <div class='title-wrapper'>
              <p class='category'>${capitalizeFirstLetter(category || school.category)}</p>
              <h4 class='title'>${school.name}</h4>
            </div>
            <div class='attributes'>
              ${
                school.type === 'SCHOOL'
                  ? `
                <div>
                  <p class='title'>Zameranie:</p>
                  <div class='value'>${school.specialization}</div>
                </div>
              `
                  : ''
              } 
              ${
                school.type === 'UNIVERSITY'
                  ? `
                <div>
                  <p class='title'>Zriaďovateľ:</p>
                  <div class='value'>${school.founder}</div>
                </div>
              `
                  : ''
              } 
              <div>
                <p class='title'>Lokácia:</p>
                <div class='value'>${school.city}</div>
              </div>
              ${
                school.type === 'SCHOOL'
                  ? `
                <div>
                  <p class='title'>Dĺžka štúdia:</p>
                  <div class='value'>${school.length}</div>
                </div>
              `
                  : ''
              } 
              <div>
                <p class='title'>Vyučovací jazyk:</p>
                <div class='value'>
                  ${limitedLanguages
                    ?.map(lang => {
                      const url = `../img/flags/${lang}.png`
                      return `
                    <span class="flag-wrapper">
                      <span title='${lang}' class="flag"><img src="${url}" alt="" /></span>
                      &nbsp;
                      <span>${lang}</span>
                    </span>  
                  `
                    })
                    .join('')}
                  ${isMoreThanLimit ? `<div class="more-button">+ viac</div>` : ''}    
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    `
  }

const filterResults = () => {
  const listMiddleWrapper = document.querySelector('.school-middle-list')
  const listHighWrapper = document.querySelector('.school-high-list')
  const listEmployersWrapper = document.querySelector('.employers-list')

  if (listMiddleWrapper) {
    listMiddleWrapper.innerHTML = mockDataSchools
      .filter(school => (isActiveFilter ? school?.careerEmployers?.length : true))
      .map(schoolCardComponent('school'))
      .join('')
  }
  if (listEmployersWrapper) {
    listEmployersWrapper.innerHTML = mockDataEmployers.map(employerCardComponent).join('')
  }
  if (listHighWrapper) {
    listHighWrapper.innerHTML = mockDataHighSchools
      .flatMap(university => {
        const faculties = mockDataFaculties
          .filter(faculty => faculty.schoolId === university.id)
          .flatMap(schoolCardComponent('faculty', university.name))
        return faculties
      })
      .join('')
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const employerCards = document.querySelectorAll('.employer-card')
  const filterButton = document.querySelector('.filter-button')

  filterButton?.addEventListener('click', () => {
    isActiveFilter = !isActiveFilter
    if (isActiveFilter) filterButton.classList.add('active')
    else filterButton.classList.remove('active')
    filterResults()
  })

  filterResults()

  if (employerCards) {
    employerCards.forEach(card => {
      console.log(card.dataset)
      const employerId = card.dataset?.employerId
      const employer = mockDataEmployers.find(e => e.id.toString() === employerId)
      if (!employer) return
      card.innerHTML = employerCardComponent(employer)
    })
  }
})
