document.addEventListener('DOMContentLoaded', event => {
  const mobileHeader = document.getElementById('sticky-mobile-header')
  const mobileSticky = mobileHeader.offsetTop
  const desktopHeader = document.getElementById('sticky-desktop-header')
  const desktopSticky = desktopHeader.offsetTop

  function stickMenu() {
    if (window.pageYOffset > mobileSticky && window.getComputedStyle(mobileHeader.parentElement).display !== 'none') {
      mobileHeader.classList.add('sticky')
      mobileHeader.parentElement.style.paddingTop = `${mobileHeader.offsetHeight}px`
    } else {
      mobileHeader.classList.remove('sticky')
      mobileHeader.parentElement.style.paddingTop = '0px'
    }
    if (window.pageYOffset > desktopSticky && window.getComputedStyle(desktopHeader).display !== 'none') {
      desktopHeader.parentElement.classList.add('sticky')
      desktopHeader.parentElement.nextElementSibling.style.marginTop = `${desktopHeader.offsetHeight}px`
    } else {
      desktopHeader.parentElement.classList.remove('sticky')
      desktopHeader.parentElement.nextElementSibling.style.marginTop = '0px'
    }
  }

  window.onscroll = stickMenu
})
