const cookieValues = {
  analyticalDeclined: window.localStorage?.getItem('analytical-declined') === 'true',
  statisticalDeclined: window.localStorage?.getItem('statistical-declined') === 'true',
  marketingDeclined: window.localStorage?.getItem('marketing-declined') === 'true'
}

const saveCookiesChanges = () => {
  const cookieNotice = document.querySelector('.important-notice')
  cookieNotice?.remove()
  window.localStorage?.setItem('read-important-notice', 'true')
  window.localStorage?.setItem('analytical-declined', Boolean(cookieValues.analyticalDeclined).toString())
  window.localStorage?.setItem('statistical-declined', Boolean(cookieValues.statisticalDeclined).toString())
  window.localStorage?.setItem('marketing-declined', Boolean(cookieValues.marketingDeclined).toString())
  window.toggleGlobalModal(false)
  const saveChangesButtons = document.querySelectorAll('.save-customized-important-notice')
  saveChangesButtons?.forEach(saveChangesButton => saveChangesButton?.classList.remove('filled'))
}

const allowAllCookies = () => {
  cookieValues.analyticalDeclined = false
  cookieValues.statisticalDeclined = false
  cookieValues.marketingDeclined = false
  saveCookiesChanges()
}

const listener = resetToFalse => () => {
  // Show important-notice bar if it is not saved yet
  const cookieNotice = document.querySelector('.important-notice')
  const isRead = window.localStorage?.getItem('read-important-notice') === 'true'
  // If message is not read, show it on screen
  if (cookieNotice && !isRead) cookieNotice.removeAttribute('style')

  const saveChangesButtons = document.querySelectorAll('.save-customized-important-notice')
  saveChangesButtons?.forEach(item => item.addEventListener('click', saveCookiesChanges))

  const acceptAllButtons = document.querySelectorAll('.accept-all-important-notice')
  acceptAllButtons?.forEach(item => item.addEventListener('click', allowAllCookies))

  if (document.querySelectorAll('.slider-toggle')?.length) {
    const sliderToggleItems = document.querySelectorAll('.slider-toggle')
    // eslint-disable-next-line complexity
    sliderToggleItems.forEach(item => {
      // Predefined values
      if (resetToFalse) {
        item.classList.remove('active')
        cookieValues.analyticalDeclined = true
        cookieValues.statisticalDeclined = true
        cookieValues.marketingDeclined = true
      } else {
        if (item.id === 'toggle-analytical' && cookieValues.analyticalDeclined) item.classList.remove('active')
        else if (item.id === 'toggle-statistical' && cookieValues.statisticalDeclined) item.classList.remove('active')
        else if (item.id === 'toggle-marketing' && cookieValues.marketingDeclined) item.classList.remove('active')
      }

      // Event listener to change values
      item.addEventListener('click', () => {
        saveChangesButtons?.forEach(saveChangesButton => saveChangesButton?.classList.add('filled'))
        const isActive = !item.classList.contains('active')
        item.classList.toggle('active')

        // Toggle Manage Cookies sliders
        if (item.id === 'toggle-analytical') cookieValues.analyticalDeclined = !isActive
        else if (item.id === 'toggle-statistical') cookieValues.statisticalDeclined = !isActive
        else if (item.id === 'toggle-marketing') cookieValues.marketingDeclined = !isActive
      })
    })
  }
}

document.addEventListener('DOMContentLoaded', listener())
document.addEventListener('openModal', listener(true))
