document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelectorAll('.switcher-wrapper')?.length) return

  let activeSwitch = 1
  document.querySelectorAll('.switcher-wrapper').forEach(wrapperItem => {
    wrapperItem.classList.add(`active-${activeSwitch}`)
  })

  const switcherItems = document.querySelectorAll('.switcher-wrapper .switcher li')
  switcherItems.forEach(item => {
    const classesToRemove = [...Array(switcherItems.length).keys()].map((switcherItemIndex) => `active-${switcherItemIndex + 1}`)
    item.addEventListener('click', event => {
      const wrapper = event.target.closest('.switcher-wrapper')
      const switcher = event.target.closest('.switcher')
      const index = Array.prototype.indexOf.call(switcher.children, event.target)
      activeSwitch = index + 1

      wrapper.classList.remove(...classesToRemove)
      wrapper.classList.add(`active-${activeSwitch}`)
    })
  })
})
